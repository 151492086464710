import React from "react";
import { Form, Input, Space, Button, InputNumber, Select } from "antd";

const DeviceForm = ({
  onChange,
  fields,
  onCancel,
  onOK,
  branches,
  deviceTypes,
}) => {
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="device_form"
      layout="horizontal"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Form.Item
        name="branchRef"
        label="Şube "
        rules={[
          {
            required: true,
            message: "Şube alanı zorunludur!",
          },
        ]}
      >
        <Select options={branches} />
      </Form.Item>
      <Form.Item
        name="deviceType"
        label="Cihaz Türü "
        rules={[
          {
            required: true,
            message: "Cihaz Türü zorunludur!",
          },
        ]}
      >
        <Select options={deviceTypes} />
      </Form.Item>
      <Form.Item
        name="deviceSerial"
        label="Cihaz Seri No"
        rules={[
          {
            required: true,
            message: "Cihaz Seri No alanı zorunludur!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default DeviceForm;
