import { Col, Row, Radio, Card, Typography } from "antd";
import DashBoarsCard from "../common/dash_board_card";
import { CreditCardOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

const HomePage = () => {
  const onChange = (value) => {};
  const { Title, Text } = Typography;

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
          <Card bordered={false} className="criclebox cardbody h-full">
            <div className="project-ant">
              <div>
                <Title level={5}>İşlem Raporu</Title>
                <Paragraph className="lastweek">
                  Tahsilat toplamı : <span className="blue">15.500 TL</span>
                </Paragraph>
              </div>
              <div className="ant-filtertabs">
                <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                  <Radio.Group onChange={onChange} defaultValue="d">
                    <Radio.Button value="d">Bugün</Radio.Button>
                    <Radio.Button value="w">Bu Hafta</Radio.Button>
                    <Radio.Button value="m">Bu Ay</Radio.Button>
                    <Radio.Button value="y">Bu Yıl</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
                <DashBoarsCard title="Satış Toplamı" amount="12.500 TL" />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
                <DashBoarsCard title="İade Toplamı" amount="12.500 TL" />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
                <DashBoarsCard title="Başarılı İşlem" amount="12.500 TL" />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
                <DashBoarsCard title="Başarısız İşlem" amount="12.500 TL" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
