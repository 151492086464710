import { useState } from "react";
import logo from "../../images/logo.png";

import {
  HomeOutlined,
  HddOutlined,
  AndroidOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Image, Menu, Avatar, Space, Typography, Divider } from "antd";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Ana Sayfa", "1", <HomeOutlined />),
  getItem("Şube Listesi", "2", <HddOutlined />),
  getItem("Cihaz Listesi", "3", <AndroidOutlined />),
  getItem("Pos İşlemleri", "4", <CreditCardOutlined />),
  getItem("Kullanıcı Listesi", "5", <UserOutlined />),
  getItem("Çıkış", "6", <LogoutOutlined />),
];

const CmpMenu = ({ onClick, logedInUser }) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ textAlign: "center" }}>
        <Image src={logo} width={200} preview={false}></Image>
      </div>
      <Divider></Divider>
      <Space style={{ marginLeft: 5 }}>
        <Avatar shape="square" size={32} icon={<UserOutlined />} />
        <Typography.Title style={{ textAlign: "center", fontSize: 14 }}>
          {logedInUser}
        </Typography.Title>
      </Space>
      <Divider></Divider>
      <Menu
        onClick={onClick}
        style={{
          width: 250,
        }}
        defaultSelectedKeys={["1"]}
        items={items}
      />
      <Divider></Divider>
      <div style={{ textAlign: "center", fontSize: 10 }}>version : 1.0.1</div>
      <Divider></Divider>
    </div>
  );
};

export default CmpMenu;
