import React, { useEffect, useState } from "react";
import { SearchOutlined, FileAddOutlined } from "@ant-design/icons";
import { Table, Input, Button, Popconfirm, Space, Tag, Modal } from "antd";
import { del, getAll, save } from "../../infrastructure/ttposwebservice";
import newDevice from "../../infrastructure/field_sets/newDevice";
import DeviceForm from "./device_form";
import device_types from "../../infrastructure/field_sets/device_types";

const DeviceList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [fields, setFields] = useState({});
  const [open, setOpen] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [branches, setBranches] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);

  const [searchText, setSearchText] = useState("");

  let [filteredData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getAll("device").then((result) => {
      setLoading(false);

      filteredData = result.filter((value) => {
        return value.deviceSerial
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });

      console.log(searchText);

      setData(filteredData);
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };
  const handleDelete = async (value) => {
    await del("device", value);
    await loadData();
  };

  const addNew = async () => {
    var branches = await getAll("branch");
    var valueItems = branches.map(function (value) {
      return {
        value: value.id,
        label: value.branchCode + "- " + value.branchName,
      };
    });

    setBranches(valueItems);
    setDeviceTypes(device_types);
    setFields(newDevice);
    setOpen(1);
  };

  const edit = async (record) => {
    var branches = await getAll("branch");
    var valueItems = branches.map(function (value) {
      return {
        value: value.id,
        label: value.branchCode + "- " + value.branchName,
      };
    });

    setBranches(valueItems);
    setDeviceTypes(device_types);

    console.log(valueItems);

    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["deviceType"],
        value: record.deviceType,
      },
      {
        name: ["deviceSerial"],
        value: record.deviceSerial,
      },
      {
        name: ["branchRef"],
        value: record.branchRef,
      },
    ]);

    console.log(fields);

    setOpen(1);
  };

  const handleOk = async (values) => {
    console.log(values);
    if (values.deviceSerial === undefined) {
      return;
    }

    if (values.branchRef === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newDevice = await save("device", values);
    await loadData();
    setConfirmLoading(false);
    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const columns = [
    {
      title: "Şube Kodu",
      dataIndex: "branchCode",
      align: "center",
      sorter: (a, b) => a.branchCode.localeCompare(b.branchCode),
      sortOrder: sortedInfo.columnKey === "branchCode" && sortedInfo.order,
    },
    {
      title: "Şube Açıklaması",
      dataIndex: "branchName",
      align: "center",
      sorter: (a, b) => a.branchName.localeCompare(b.branchName),
      sortOrder: sortedInfo.columnKey === "branchName" && sortedInfo.order,
    },
    {
      title: "Cihaz Türü",
      dataIndex: "deviceTypeText",
      align: "center",
      sorter: (a, b) => a.deviceTypeText.localeCompare(b.deviceTypeText),
      sortOrder: sortedInfo.columnKey === "deviceTypeText" && sortedInfo.order,
    },
    {
      title: "Cihaz Seri No",
      dataIndex: "deviceSerial",
      align: "center",
      sorter: (a, b) => a.deviceSerial.localeCompare(b.deviceSerial),
      sortOrder: sortedInfo.columnKey === "deviceSerial" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    console.log(searchText);
    filteredData = await data.filter((value) => {
      return value.deviceSerial
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    console.log(searchText);

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Cihaz Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <DeviceForm
          branches={branches}
          deviceTypes={deviceTypes}
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Space>
        <Button onClick={addNew} type="primary" icon={<FileAddOutlined />}>
          Yeni Cihaz
        </Button>

        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default DeviceList;
