const newBranch = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["branchCode"],
    value: "",
  },
  {
    name: ["branchName"],
    value: "",
  },
];

export default newBranch;
