import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Table,
  Input,
  Button,
  DatePicker,
  Space,
  Tag,
  Checkbox,
  Select,
} from "antd";
import {
  getAllBranches,
  getAllPosMessages,
  getExcelReportLink,
} from "../../infrastructure/ttposwebservice";
import dayjs from "dayjs";
import ExcelExport from "../../infrastructure/excel_export";

const PosMessageList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allBranches, setAllBranches] = useState(true);
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState(-1);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [excelData, setExcelData] = useState([]);

  let startDateState = useRef();
  let endDateState = useRef();

  const [excelLink, setExcelLink] = useState("");

  let [filteredData] = useState();
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    var newDate = dayjs(new Date());

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    //setExcelLink(
    //  getLink(
    //    new Date(year, month, day).getTime(),
    //    new Date(year, month, day, 23, 59, 59).getTime()
    //  )
    //);

    startDateState.current = new Date(year, month, day).getTime();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();

    getAllBranches().then((branchList) => {
      getAllPosMessages(
        searchText,
        new Date(year, month, day).getTime(),
        new Date(year, month, day, 23, 59, 59).getTime(),
        allBranches ? -1 : branchId
      ).then((result) => {
        result.map((el) => {
          let date = new Date(el.tranDate);
          el.actDate =
            date.toLocaleDateString() + " - " + date.toLocaleTimeString();
        });

        var valueItems = branchList.map(function (v) {
          return {
            value: v.id,
            label: v.branchCode + "- " + v.branchName,
          };
        });
        //console.log(valueItems);
        setBranches(valueItems);

        setLoading(false);
        setData(result);

        setExcelData(
          result.map((item) => {
            return {
              tarih: item.actDate,
              seriNo: item.serialNo,
              islem_turu: item.messageType,
              hata_kodu: item.errorCode,
              batch_no: item.batchNo,
              stan_no: item.stanNo,
              card_no: item.cardNo,
              auth_code: item.authCode,
              acquirer_id: item.acquirerID,
              terminal_id: item.terminalID,
              ref_no: item.refNo,
              amount: item.amount,
              extra1: item.extra1,
            };
          })
        );
      });
    });
  }, []);

  /*

  useEffect(() => {
    if (isNaN(startDate) || isNaN(endDate)) {
      return;
    }
    
    loadData();
  }, [startDate, endDate]);*/

  const loadData = async () => {
    setLoading(true);
    //setExcelLink(
    //  getLink(startDateState.current, endDateState.current, branchId)
    //);

    getAllPosMessages(
      searchText,
      startDateState.current,
      endDateState.current,
      allBranches ? -1 : branchId
    ).then((result) => {
      result.map((el) => {
        let date = new Date(el.tranDate);
        el.actDate =
          date.toLocaleDateString() + " - " + date.toLocaleTimeString();
      });
      setLoading(false);

      setData(result);

      setExcelData(
        result.map((item) => {
          return {
            tarih: item.actDate,
            seriNo: item.serialNo,
            islem_turu: item.messageType,
            hata_kodu: item.errorCode,
            batch_no: item.batchNo,
            stan_no: item.stanNo,
            card_no: item.cardNo,
            auth_code: item.authCode,
            acquirer_id: item.acquirerID,
            terminal_id: item.terminalID,
            ref_no: item.refNo,
            amount: item.amount,
            extra1: item.extra1,
          };
        })
      );
    });
  };

  const getLink = (sDate, eDate, branchId) => {
    console.log(sDate);
    return getExcelReportLink(
      searchText,
      sDate,
      eDate,
      allBranches ? -1 : branchId
    );
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "actDate",
      align: "center",
      width: 190,
      sorter: (a, b) => a.createdAt > b.createdAt,
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
    },
    {
      title: "Cihaz Seri No",
      dataIndex: "serialNo",
      align: "center",
      sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
    },
    {
      title: "İşlem Türü",
      dataIndex: "messageType",
      align: "center",
      render: (messageType) => (
        <Space>
          <Tag
            color={messageType == 1 ? "blue" : "red"}
            style={{ width: 70, textAlign: "center" }}
          >
            {messageType == 1 ? "Tahsilat" : "İptal"}
          </Tag>
        </Space>
      ),
      sorter: (a, b) => a.messageType > b.messageType,
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
    },
    {
      title: "İşlem Durumu",
      dataIndex: "errorCode",
      align: "center",
      sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
      render: (errorCode) => (
        <Space>
          <Tag
            color={errorCode == 0 ? "green" : "red"}
            style={{ width: 70, textAlign: "center" }}
          >
            {errorCode == 0 ? "Başarılı" : "Başarısız"}
          </Tag>
          <Tag style={{ width: 30, textAlign: "center" }}> {errorCode}</Tag>
        </Space>
      ),
    },

    {
      title: "Batch No",
      dataIndex: "batchNo",
      align: "center",
      sorter: (a, b) => a.batchNo > b.batchNo,
      sortOrder: sortedInfo.columnKey === "batchNo" && sortedInfo.order,
    },
    {
      title: "Stan No",
      dataIndex: "stanNo",
      align: "center",
      sorter: (a, b) => a.stanNo > b.stanNo,
      sortOrder: sortedInfo.columnKey === "stanNo" && sortedInfo.order,
    },
    {
      title: "Kart No",
      dataIndex: "cardNo",
      align: "center",
      sorter: (a, b) => a.cardNo.localeCompare(b.cardNo),
      sortOrder: sortedInfo.columnKey === "cardNo" && sortedInfo.order,
    },
    {
      title: "Auth Code",
      dataIndex: "authCode",
      align: "center",
      sorter: (a, b) => a.authCode.localeCompare(b.authCode),
      sortOrder: sortedInfo.columnKey === "authCode" && sortedInfo.authCode,
    },
    {
      title: "Acquirer ID",
      dataIndex: "acquirerID",
      align: "center",
      sorter: (a, b) => a.acquirerID > b.acquirerID,
      sortOrder: sortedInfo.columnKey === "acquirerID" && sortedInfo.acquirerID,
    },
    {
      title: "Terminal No",
      dataIndex: "terminalID",
      align: "center",
      sorter: (a, b) => a.acquirerID > b.acquirerID,
      sortOrder: sortedInfo.columnKey === "acquirerID" && sortedInfo.acquirerID,
    },
    {
      title: "Ref No",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo.localeCompare(b.refNo),
      sortOrder: sortedInfo.columnKey === "refNo" && sortedInfo.refNo,
    },

    {
      title: "Tutar",
      dataIndex: "amount",
      align: "right",
      render: (amount) => (
        <Space>
          <Tag color="volcano">{amount.toFixed(2)}</Tag>
        </Space>
      ),
      sorter: (a, b) => a.amount > b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
    },
    {
      title: "Extra Info 1",
      dataIndex: "extra1",
      sorter: (a, b) => a.extra1.localeCompare(b.extra1),
      sortOrder: sortedInfo.columnKey === "extra1" && sortedInfo.extra1,
    },
  ];

  const globalSearch = async (e) => {
    console.log("Global search ");
    await loadData();
    filteredData = await data.filter((value) => {
      return value.serialNo.toLowerCase().includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  const onStartDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    //setStartDate(new Date(year, month, day));
    startDateState.current = new Date(year, month, day).getTime();
  };

  const onEndDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();
  };

  const checkChanged = (e) => {
    setAllBranches(e.target.checked);
  };

  const onSelectedBranchChanged = (e) => {
    setBranchId(e);
  };

  return (
    <div>
      <Space direction="vertical">
        <Space>
          <DatePicker
            format={dateFormat}
            defaultValue={dayjs(startDate)}
            onChange={onStartDateChange}
          />
          <DatePicker
            format={dateFormat}
            defaultValue={dayjs(endDate)}
            onChange={onEndDateChange}
          />

          <Input
            style={{ width: 200, marginLeft: 10, marginRight: 10 }}
            placeholder="Filtre"
            type="text"
            allowClear
            value={searchText}
            onChange={handleInputChange}
          />
          <Button
            onClick={globalSearch}
            type="primary"
            icon={<SearchOutlined />}
          >
            Listele
          </Button>
          <ExcelExport excelData={excelData} fileName={"Report"} />
          <Tag color="orange">({data.length})</Tag>
        </Space>
        <Space>
          <Checkbox checked={allBranches} onChange={checkChanged}>
            Tüm Şubeler
          </Checkbox>
          {!allBranches && (
            <Select
              options={branches}
              onChange={onSelectedBranchChanged}
              style={{
                width: 380,
              }}
            />
          )}
        </Space>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default PosMessageList;

/*
<Button type="link" href={excelLink} icon={<FileExcelOutlined />}>
            Excel Rapor
          </Button>*/
