import { Card, Row, Col, Typography } from "antd";
const DashBoarsCard = ({ title, className, amount, desc, icon }) => {
  const { Title } = Typography;

  return (
    <Card bordered className="criclebox" style={{ margin: 10 }}>
      <div className="number">
        <Row align="middle" gutter={[48, 0]}>
          <Col xs={42}>
            <span>{title}</span>
            <Title level={5}>
              {amount}
              <small className={className}>{desc}</small>
            </Title>
          </Col>
          <Col xs={6}>
            <div className="icon-box">{icon}</div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default DashBoarsCard;
