const newDevice = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["deviceType"],
    value: null,
  },
  {
    name: ["deviceSerial"],
    value: "",
  },
  {
    name: ["branchRef"],
    value: null,
  },
];

export default newDevice;
