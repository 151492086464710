import axios from "axios";

//const API_BASE_URL = "http://139.162.164.68:8080/horder";
const API_BASE_URL = "https://server.gurkanucar.com/horder";
//const API_BASE_URL = "http://localhost:8080";

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 360000,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("Axios error:", error);
    return Promise.reject(error);
  }
);

export const getAllBranches = async () => {
  const response = await instance.get(`/api/branch`);
  return response.data;
};

export const getAllPosMessagesBySerialNo = async (serialNo) => {
  const response = await instance.get(`/api/posMessage/${serialNo}`);
  return response.data;
};

export const save = async (modelName, model) => {
  const response = await instance.post(`/api/${modelName}`, model);

  return response.data;
};

export const login = async (model) => {
  const response = await instance.post(`/api/user/login`, model);
  console.log(response);
  return response.data;
};

export const del = async (modelName, model) => {
  const response = await instance.delete(`/api/${modelName}/${model.id}`);
  console.log(response);
  return response.data;
};

export const getAll = async (modelName) => {
  const response = await instance.get(`/api/${modelName}`);
  return response.data;
};
export const getAllPosMessages = async (
  filterText,
  startDate,
  endDate,
  branchId
) => {
  if (filterText == "") {
    filterText = "*";
  }
  const response = await instance.get(
    `/api/posMessage/${filterText}/${startDate}/${endDate}/${branchId}`
  );
  return response.data;
};

export const getExcelReportLink = (
  filterText,
  startDate,
  endDate,
  branchId
) => {
  if (filterText == "") {
    filterText = "*";
  }
  return `${API_BASE_URL}/api/posMessage/excel/${filterText}/${startDate}/${endDate}/${branchId}`;
};
