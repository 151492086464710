import React, { useEffect, useState } from "react";
import { Table, Input, Button, Popconfirm, Space, Tag, Modal } from "antd";
import { del, getAll, save } from "../../infrastructure/ttposwebservice";
import UserForm from "./user_form";
import { SearchOutlined, FileAddOutlined } from "@ant-design/icons";

const UserList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [fields, setFields] = useState({});
  const [open, setOpen] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setRecord] = useState({});

  const [searchText, setSearchText] = useState("");

  let [filteredData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getAll("user").then((result) => {
      setLoading(false);

      filteredData = result.filter((value) => {
        return value.userName.toLowerCase().includes(searchText.toLowerCase());
      });

      console.log(searchText);

      setData(filteredData);
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };
  const handleDelete = async (value) => {
    await del("user", value);
    await loadData();
  };

  const addNew = async () => {
    setFields([
      {
        name: ["id"],
        value: 0,
      },
      {
        name: ["userName"],
        value: "",
      },
      {
        name: ["password"],
        value: "",
      },
    ]);
    setOpen(1);
  };

  const edit = async (record) => {
    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["userName"],
        value: record.userName,
      },
      {
        name: ["password"],
        value: record.password,
      },
    ]);

    console.log(fields);

    setOpen(1);
  };

  const handleOk = async (values) => {
    console.log(values);
    if (values.userName === undefined) {
      return;
    }

    if (values.password === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newUser = await save("user", values);
    await loadData();
    setConfirmLoading(false);
    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const columns = [
    {
      title: "Kullanıcı Adı",
      dataIndex: "userName",
      align: "center",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === "userName" && sortedInfo.userName,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    console.log(searchText);
    filteredData = await data.filter((value) => {
      return value.userName.toLowerCase().includes(searchText.toLowerCase());
    });

    console.log(searchText);

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Kullanıcı Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <UserForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Space>
        <Button onClick={addNew} type="primary" icon={<FileAddOutlined />}>
          Yeni Kullanıcı
        </Button>

        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default UserList;
